// Typedef Helpers
/**
 * @hidden
 */
import {ApiState} from "../api-state"
import {UnbluApiFactory} from "./unblu-api-factory"
import {InitializedUnbluApi} from "./initialized-unblu-api"
import {InitializedUnbluElement} from "./initialized-unblu-element"
import {UnbluApiError, UnbluErrorType} from "../unblu-api-error";
import {Configuration} from "../model/configuration";

export enum IntegrationType {
    embedded = 'embedded',
    floating = 'floating'
}

enum EntryPoint {
    siteIntegrationGreedyMain = 'SiteIntegrationGreedyMain',
    siteIntegrationLazyMain = 'SiteIntegrationLazyMain',
    siteEmbeddedMain = 'SiteEmbeddedMain'
}

/**
 * Internal type definition of the unblu object.
 */
export interface UnbluObject {
    api?: UnbluApiFactory,

    /**
     * internal unblu field
     * @hidden
     */
    APIKEY?: string,
    /**
     * internal unblu field
     * @hidden
     */
    SERVER?: string,
    /**
     * internal unblu field
     * @hidden
     */
    l?: string,
    /**
     * internal unblu field
     * @hidden
     */
    entryPoint?: EntryPoint
    /**
     * internal unblu field
     * @hidden
     */
    globalPrefix?: string
}

class NamedAreaMetaTag extends Element {
    originalContent: string
}

interface UnbluInternal {
    $_baseCfg: BaseConfig
}

interface BaseConfig {
    entryPath?: string
}

export class UnbluUtil {
    static async loadScript(uri: string, timeout: number): Promise<void> {
        const timeoutTime = timeout || 30000
        const script = document.createElement('script')
        script.setAttribute('charset', 'UTF-8')
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('async', 'true')
        script.setAttribute('timeout', timeoutTime.toString())
        script.src = uri

        return new Promise<void>(function (resolve, reject) {
            let timeoutId: number

            const cleanup = () => {
                // avoid mem leaks in IE.
                script.onerror = script.onload = null
                window.clearTimeout(timeoutId)
            }

            const onError = (error: string | Event) => {
                cleanup()
                console.error('Failed to load script! Uri:', uri, 'Error:', error)
                reject(error)
            }

            script.onload = () => {
                cleanup()
                resolve()
            }
            script.onerror = onError
            timeoutId = window.setTimeout(() => onError('Timeout'), timeoutTime)

            const head = document.getElementsByTagName('HEAD')[0]
            head.appendChild(script)
        })
    }

    static getNamedArea(): string | undefined {
        const existingMetaTag: NamedAreaMetaTag = window.document.querySelector('meta[name="unblu:named-area"]')
        return existingMetaTag ? existingMetaTag.getAttribute('content') : undefined
    }

    static setNamedArea(namedArea: string): void {
        const existingMetaTag: NamedAreaMetaTag = window.document.querySelector('meta[name="unblu:named-area"]')
        if (existingMetaTag && !existingMetaTag.originalContent) {
            existingMetaTag.originalContent = existingMetaTag.getAttribute('content')
        }
        const metaTag = existingMetaTag || window.document.createElement('meta')
        metaTag.setAttribute('name', 'unblu:named-area')
        metaTag.setAttribute('content', namedArea)
        if (!metaTag.parentElement) {
            window.document.head.appendChild(metaTag)
        }
    }

    static removeNamedArea(): void {
        const metaTag: NamedAreaMetaTag = window.document.querySelector('meta[name="unblu:named-area"]')

        if (metaTag?.originalContent) {
            metaTag.setAttribute('content', metaTag.originalContent)
            metaTag.originalContent = null
        } else if (metaTag) {
            metaTag.remove()
        }
    }

    static setLocale(locale: string): void {
        // unblu.l will be read in user-locale-util.js
        UnbluUtil.getOrCreateUnbluObject().l = locale
    }

    static async loginWithSecureToken(serverUrl: string, apiKey: string, entryPath: string, accessToken: string): Promise<void> {
        const url = `${serverUrl}${entryPath}/rest/v3/authenticator/loginWithSecureToken?x-unblu-apikey=${apiKey}`;
        let response;
        try {
            response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                body: JSON.stringify({
                    token: accessToken,
                    type: 'JWT'
                })
            });
        } catch (e) {
            throw new UnbluApiError(UnbluErrorType.AUTHENTICATION_FAILED, `Failed to authenticate with provided access token! Reason: ${e}`);
        }
        if(!response.ok) {
            throw new UnbluApiError(UnbluErrorType.AUTHENTICATION_FAILED, `Failed to authenticate with provided access token! Status: ${response.status}; StatusText: ${response.statusText}`);
        }
    }

    static async isAuthenticated(serverUrl: string, entryPath: string): Promise<boolean> {
        const url = `${serverUrl}${entryPath}/rest/v3/authenticator/isAuthenticated`;
        let response;
        try {
            response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
            });
        } catch (e) {
            throw new UnbluApiError(UnbluErrorType.AUTHENTICATION_FAILED, `Failed to check if authenticated! Reason: ${e}`);
        }
        if(!response.ok) {
            throw new UnbluApiError(UnbluErrorType.AUTHENTICATION_FAILED, `Failed to check if authenticated! Status: ${response.status}; StatusText: ${response.statusText}`);
        }
        return true === await response.json();
    }

    static async logout(serverUrl: string, entryPath: string): Promise<void> {
        const url = `${serverUrl}${entryPath}/rest/v3/authenticator/logout`;
        let response;
        try {
            response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                body: ''
            });
        } catch (e) {
            throw new UnbluApiError(UnbluErrorType.AUTHENTICATION_FAILED, `Failed to logout! Reason: ${e}`);
        }
        if(!response.ok) {
            throw new UnbluApiError(UnbluErrorType.AUTHENTICATION_FAILED, `Failed to logout! Status: ${response.status}; StatusText: ${response.statusText}`);
        }
    }

    static isUnbluLoaded(integrationType: IntegrationType): boolean {
        return UnbluUtil.getUnbluObject() && !!UnbluUtil.getUnbluObject().APIKEY && UnbluUtil.entryPointMatches(integrationType)
    }

    static generateConfigurationFromLoadedUnblu(): Configuration {
        const unblu = UnbluUtil.getUnbluObject()
        // @ts-ignore
        const unbluInternal: UnbluInternal = window[unblu.globalPrefix];
        return {
            apiKey: unblu.APIKEY,
            serverUrl: unblu.SERVER,
            entryPath: unbluInternal?.$_baseCfg.entryPath,
            locale: unblu.l,
            namedArea: UnbluUtil.getNamedArea()
        }
    }

    private static entryPointMatches(integrationType: IntegrationType): boolean {
        if (integrationType === IntegrationType.embedded) {
            return UnbluUtil.getUnbluObject().entryPoint === EntryPoint.siteEmbeddedMain
        } else if (integrationType === IntegrationType.floating) {
            return UnbluUtil.getUnbluObject().entryPoint === EntryPoint.siteIntegrationGreedyMain || UnbluUtil.getUnbluObject().entryPoint === EntryPoint.siteIntegrationLazyMain
        } else {
            return false
        }
    }

    static getUnbluObject(): UnbluObject {
        // @ts-ignore
        return window.unblu as unknown as UnbluObject
    }

    static createUnbluObject(): UnbluObject {
        // @ts-ignore
        return window.unblu = {}
    }

    static getOrCreateUnbluObject(): UnbluObject {
        // @ts-ignore
        return window.unblu || (window.unblu = {})
    }

    static async deinitializeFloatingIfNeeded() {
        if (!UnbluUtil.isUnbluLoaded(IntegrationType.floating)) {
            return
        }
        const apiState = UnbluUtil.getUnbluObject().api.getApiState()
        let unbluApi: InitializedUnbluApi
        if (apiState == ApiState.INITIALIZING || apiState == ApiState.INITIALIZED) {
            unbluApi = await UnbluUtil.getUnbluObject().api.initialize()
            await unbluApi.deinitialize()
        }
    }

    static async deinitializeEmbeddedIfNeeded(excludedAppElement?: InitializedUnbluElement) {
        const unbluAppElements = document.getElementsByTagName('unblu-embedded-app')
        for (let i = 0; i < unbluAppElements.length; i++) {
            const appElement = unbluAppElements[i] as InitializedUnbluElement
            if (appElement === excludedAppElement) {
                continue
            }
            console.log("Deinitializing existing embedded integration:", appElement)
            await appElement.deinitialize()
        }
    }

    static sanitizeParameter(param: string): string {
        if (!param || !param.trim()) {
            return null
        }
        return param
    }
}