import {CallState} from './model/call-state'
import {Event, EventCallback} from './internal/event'
import {
    ConversationCallState,
    ConversationConnectionState,
    ConversationEventType,
    ConversationInvitations,
    ConversationModule,
    ConversationWithState
} from './internal/module/conversation-module'
import {UnbluApiError, UnbluErrorType} from './unblu-api-error'
import {EventEmitter, Listener} from './internal/util/event-emitter'
import {ConversationState} from './model/conversation-state'
import {ConnectionState} from './model/connection-state'
import {Invitation} from "./model/invitation"
import {CustomActionInvocation} from './model/customaction/custom-action-invocation'

export type ConnectionStateListener = (connectionState: ConnectionState) => void
export type ConversationStateListener = (conversationState: ConversationState) => void
export type CallStateListener = (callState: CallState) => void
export type InvitationsListener = (invitations: Invitation[]) => void
/**
 * Listener called whenever a custom action invocation triggers an API event for the client
 * @param customActionInvocation The details of the custom action invocation
 */
export type CustomActionInvocationListener = (customActionInvocation: CustomActionInvocation) => void

/**
 * #### This class gives API access to the currently active conversation.
 *
 * As long as a conversation is active one can register and receive the events provided by this class and call the methods.
 * Once the conversation is closed this API object will be destroyed and no more event callbacks will be called.
 * Any subsequent calls will fail.
 *
 * Use the {@link CLOSE} event to de-init any code connected to this conversation.
 */
export class Conversation {

    /**
     * Event emitted when the {@link ConnectionState} of this conversation changes.
     * @event connectionStateChange
     * @see {@link on} for listener registration
     */
    public static readonly CONNECTION_STATE_CHANGE: 'connectionStateChange' = 'connectionStateChange'

    /**
     * Event emitted when the {@link ConversationState} of this conversation changes.
     * @event conversationStateChange
     * @see {@link on} for listener registration
     */
    public static readonly CONVERSATION_STATE_CHANGE: 'conversationStateChange' = 'conversationStateChange'

    /**
     * Event emitted when the {@link CallState} of this conversation changes.
     * @event callStateChange
     * @see {@link on} for listener registration
     */
    public static readonly CALL_STATE_CHANGE: 'callStateChange' = 'callStateChange'

    /**
     * Event emitted when the conversation ends.
     * @deprecated The end event is not always available, depending on the configuration. Use the {@link CONVERSATION_STATE_CHANGE} event instead.
     * @event end
     * @see {@link on} for listener registration
     */
    public static readonly END: 'end' = 'end'

    /**
     * Event emitted when the conversation is closed.
     *
     * This may happen due to a UI-navigation or an API-call.
     *
     * @event close
     * @see {@link on} for listener registration
     */
    public static readonly CLOSE: 'close' = 'close'

    /**
     * Event emitted when an {@link Invitation} is added to or removed from this conversation, or if an existing invitation changes.
     * The event emits an array of all visitor invitations created by the local person, all other invitations will not be present.
     * @event invitationsChange
     * @see {@link on} for listener registration
     */
    public static readonly INVITATIONS_CHANGE: 'invitationsChange' = 'invitationsChange'
    
    /**
     * Event emitted every time a custom action is configured to trigger a JS API event for the current client when a custom action is invoked        
     *
     * @event customActionInvocation
     * @see {@link CustomActionInvocationListener}
     * @see {@link on} for listener registration     
     */
    public static readonly CUSTOM_ACTION_INVOCATION: 'customActionInvocation' = 'customActionInvocation'

    private eventEmitter = new EventEmitter()
    private internalListeners: { [key: string]: EventCallback } = {}
    private destroyed = false

    /**
     * @hidden
     */
    constructor(private conversationModule: ConversationModule, private conversationId: string) {
        // clean up all listeners when the conversation disconnects.
        this.on(Conversation.CLOSE, () => this.destroy())
    }

    /**
     * Registers an event listener for the given event.
     * @param event The call state change event.
     * @param listener The listener to be called.
     * @see {@link CONNECTION_STATE_CHANGE}
     */
    public on(event: typeof Conversation.CONNECTION_STATE_CHANGE, listener: ConnectionStateListener): void

    /**
     * Registers an event listener for the given event.
     * @param event The conversation state change event.
     * @param listener The listener to be called.
     * @see {@link CONVERSATION_STATE_CHANGE}
     */
    public on(event: typeof Conversation.CONVERSATION_STATE_CHANGE, listener: ConversationStateListener): void


    /**
     * Registers an event listener for the given event.
     * @param event The call state change event.
     * @param listener The listener to be called.
     * @see {@link CALL_STATE_CHANGE}
     */
    public on(event: typeof Conversation.CALL_STATE_CHANGE, listener: CallStateListener): void

    /**
     * Registers an event listener for the given event.
     * @deprecated The end event is not always available, depending on the configuration. Use instead {@link CONVERSATION_STATE_CHANGE} event.
     * @param event The end event.
     * @param listener The listener to be called.
     * @see {@link END}
     */
    public on(event: typeof Conversation.END, listener: () => void): void

    /**
     * Registers an event listener for the given event.
     * @param event The close event.
     * @param listener The listener to be called.
     * @see {@link CLOSE}
     */
    public on(event: typeof Conversation.CLOSE, listener: () => void): void

    /**
     * Registers an event listener for the given event.
     * @param event The invitations change event.
     * @param listener The listener to be called.
     * @see {@link INVITATIONS_CHANGE}
     */
    public on(event: typeof Conversation.INVITATIONS_CHANGE, listener: InvitationsListener): void
    
    /**
     * Registers an event listener for the given event.
     * @param event The customActionInvocation event.
     * @param listener The listener to be called.
     * @see {@link CUSTOM_ACTION_INVOCATION}
     */
    public on(event: typeof Conversation.CUSTOM_ACTION_INVOCATION, listener: CustomActionInvocationListener): void

    /**
     * Adds a listener.
     * @param event The event to register.
     * @param listener The listener to register.
     */
    public on(event: ConversationEventType, listener: Listener): void {
        this.checkNotDestroyed()
        const needsInternalSubscription = !this.eventEmitter.hasListeners(event)
        this.eventEmitter.on(event, listener)
        if (needsInternalSubscription)
            this.onInternal(event)
    }

    /**
     * Removes a previously registered listener.
     * @param event The event to unregister from.
     * @param listener The listener to remove.
     */
    public off(event: ConversationEventType, listener: Listener): boolean {
        this.checkNotDestroyed()
        const removed = this.eventEmitter.off(event, listener)
        if (!this.eventEmitter.hasListeners(event))
            this.offInternal(event)
        return removed
    }

    private onInternal(eventName: ConversationEventType) {
        let internalListener: EventCallback
        switch (eventName) {
            case Conversation.CONNECTION_STATE_CHANGE:
                internalListener = (event: Event<ConversationConnectionState>) => {
                    if (event.data.conversationId == this.conversationId)
                        this.eventEmitter.emit(event.name, event.data.connectionState)
                }
                break
            case Conversation.CONVERSATION_STATE_CHANGE:
                internalListener = (event: Event<ConversationWithState>) => {
                    if (event.data.conversationId == this.conversationId)
                        this.eventEmitter.emit(event.name, event.data.conversationState)
                }
                break
            case Conversation.CALL_STATE_CHANGE:
                internalListener = (event: Event<ConversationCallState>) => {
                    if (event.data.conversationId == this.conversationId)
                        this.eventEmitter.emit(event.name, event.data.callState)
                }
                break
            case Conversation.INVITATIONS_CHANGE:
                internalListener = (event: Event<ConversationInvitations>) => {
                    if (event.data.conversationId == this.conversationId)
                        this.eventEmitter.emit(event.name, event.data.invitations)
                }
                break
            case Conversation.CUSTOM_ACTION_INVOCATION:
            	internalListener = (event: Event<CustomActionInvocation>) => {
                    if (event.data.conversation.id == this.conversationId)
                        this.eventEmitter.emit(event.name, event.data)
                }
                break
            default:
                internalListener = (event: Event<string>) => {
                    if (event.data == this.conversationId) {
                        this.eventEmitter.emit(event.name)
                    }
                }
                break
        }
        this.internalListeners[eventName] = internalListener
        this.conversationModule.on(eventName, internalListener).catch(e => console.warn('Error registering internal listener for event:', eventName, 'error:' + e, e))
    }

    private offInternal(eventName: ConversationEventType) {
        const listener = this.internalListeners[eventName]
        if (listener == null)
            return
        delete this.internalListeners[eventName]
        this.conversationModule.off(eventName, listener).catch(e => console.warn('Error removing internal listener for event:', eventName, 'error:' + e, e))
    }

    /**
     * Returns the ID of this conversation.
     */
    public getConversationId(): string {
        return this.conversationId
    }

    /**
     * Returns the current connection state the conversation is in.
     *
     * If the connection is lost, the conversation will automatically try to reconnect using an exponential back-off strategy.
     * If a fatal error is detected, the state will change to {@link ConnectionState.ERROR}.
     *
     * If this happens, the conversation is in it's terminal state. A dialog or other UI will be displayed to the user with details on the failure.
     * The conversation is not automatically closed in this case.
     * It may either be closed through a manual action by the visitor (confirming the error) or via the API.
     *
     * @see {@link CONNECTION_STATE_CHANGE} if you need to listen to changes.
     * @return A promise that resolves to the current connection state of the conversation
     * or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async getConnectionState(): Promise<ConnectionState> {
        this.checkNotDestroyed()
        return this.conversationModule.getConnectionState(this.conversationId)
    }

    /**
     * Returns the current state the conversation is in.
     * @return A promise that resolves to the current state of the conversation
     * or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async getConversationState(): Promise<ConversationState> {
        this.checkNotDestroyed()
        return this.conversationModule.getConversationState(this.conversationId)
    }

    /**
     * @see {@link CALL_STATE_CHANGE} if you need to listen to changes.
     * @return A promise that resolves to the current call state of the local user
     * or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async getCallState(): Promise<CallState> {
        this.checkNotDestroyed()
        return this.conversationModule.getCallState(this.conversationId)
    }

    /**
     * Creates a new PIN invitation for this conversation.
     *
     * - If the local person doesn't have the right to invite a visitor,
     * the returned promise will be rejected with the unblu error type {@link UnbluErrorType.ACTION_NOT_GRANTED}.
     * @see {@link INVITATIONS_CHANGE} if you need to listen to changes for new invitations on this conversation.
     * @return A promise that resolves to a new {@link Invitation} object with all relevant metadata.
     * or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async createAnonymousPinInvitation(): Promise<Invitation> {
        this.checkNotDestroyed()
        return this.conversationModule.createAnonymousPinInvitation(this.conversationId)
    }

    /**
     * Creates a new EMail invitation for this conversation.
     *
     * - If the local person doesn't have the right to invite a visitor,
     * the returned promise will be rejected with the unblu error type {@link UnbluErrorType.ACTION_NOT_GRANTED}.
     * - If a non valid email address is provided,
     * the returned promise will be rejected with the unblu error type {@link UnbluErrorType.INVALID_FUNCTION_ARGUMENTS}.
     * @param email The email which the invitation should be send. It must be a valid email address.
     * @see {@link INVITATIONS_CHANGE} If you need to listen to changes for new invitations on this conversation.
     * @return A promise that resolves to a new {@link Invitation} object with all relevant metadata.
     * or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async createAnonymousEmailInvitation(email: String): Promise<Invitation> {
        this.checkNotDestroyed()
        return this.conversationModule.createAnonymousEmailInvitation(this.conversationId, email)
    }

    /**
     * Revoke an invitation.
     *
     * - If the local person doesn't have the right to invite a visitor,
     * the returned promise will be rejected with the unblu error type {@link UnbluErrorType.ACTION_NOT_GRANTED}.
     * @param invitationId The invitation id.
     * @return A Promise that resolves to null or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async revokeInvitation(invitationId: String): Promise<void> {
        this.checkNotDestroyed()
        return this.conversationModule.revokeInvitation(this.conversationId, invitationId)
    }

    /**
     * Renews an invitation PIN if the invitation is expired.
     *
     * - If the local person doesn't have the right to invite a visitor,
     * the returned promise will be rejected with the unblu error type {@link UnbluErrorType.ACTION_NOT_GRANTED}.
     * @param invitationId The invitation id.
     * @return A promise that resolves to a new {@link Invitation} object with all relevant metadata.
     */
    public async renewInvitationPin(invitationId: String): Promise<Invitation> {
        this.checkNotDestroyed()
        return this.conversationModule.renewInvitationPin(this.conversationId, invitationId)
    }

    /**
     * Set custom visitor data on the conversation. Don't use for confidential information. Security-related
     * data should be stored in the conversation metadata.
     *
     * @param visitorData Custom data for the visitor in any format.
     */
    public async setVisitorData(visitorData: String) : Promise<void> {
        this.checkNotDestroyed();
        return this.conversationModule.setVisitorData(visitorData);
    }

    /**
     * Get the custom visitor data from the conversation.
     *
     * @return The custom visitor data in the conversation
     */
    public async getVisitorData() : Promise<String> {
        this.checkNotDestroyed();
        return this.conversationModule.getVisitorData();
    }

    /**
     * Get all visitor invitations created by the local person for this conversation.
     * @return A promise that resolves to a new {@link Invitation} array with all relevant metadata.
     * or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async getInvitations(): Promise<Invitation[]> {
        this.checkNotDestroyed()
        return this.conversationModule.getInvitations(this.conversationId)
    }

    /**
     * Starts a voice call in this conversation.
     *
     * - If a call is already active, this call will be ignored.
     * - If the local person doesn't have the right to start a voice call,
     * the returned promise will be rejected with the unblu error type {@link UnbluErrorType.ACTION_NOT_GRANTED}.
     * @see {@link CALL_STATE_CHANGE} If you need to listen to changes.
     * @return A Promise that resolves to null or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async startAudioCall(): Promise<void> {
        this.checkNotDestroyed()
        return this.conversationModule.startAudioCall(this.conversationId)
    }

    /**
     * Starts a video call in this conversation.
     *
     * - If a call is already active, this call will be ignored.
     * - If the local person doesn't have the right to start a video call,
     * the returned promise will be rejected with the unblu error type {@link UnbluErrorType.ACTION_NOT_GRANTED}.
     * @see {@link CALL_STATE_CHANGE} If you need to listen to changes.
     * @return A Promise that resolves to null or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async startVideoCall(): Promise<void> {
        this.checkNotDestroyed()
        return this.conversationModule.startVideoCall(this.conversationId)
    }

    /**
     * Ends and closes this conversation.
     *
     * If the local person doesn't have the right to end the conversation,
     * the returned promise will be rejected with the unblu error type {@link UnbluErrorType.ACTION_NOT_GRANTED}.
     * @see {@link END} fired after this call.
     * @see {@link closeConversation} for details on closing a conversation.
     * @return A Promise that resolves to null or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async endConversation(): Promise<void> {
        this.checkNotDestroyed()
        return this.conversationModule.endConversation(this.conversationId)
    }

    /**
     * Leaves and closes this conversation.
     *
     * By leaving, the visitor is removed from the active participant list of the conversation.
     * Once a conversation is left, the visitor can not re-open it. It will not be visible in the conversation history either.
     *
     * If the local person doesn't have the right to leave the conversation,
     * the returned promise will be rejected with the unblu error type {@link UnbluErrorType.ACTION_NOT_GRANTED}.
     * @see {@link CLOSE} fired after this call.
     * @see {@link closeConversation} for details on closing a conversation without leaving.
     * @return A Promise that resolves to null or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async leaveConversation(): Promise<void> {
        this.checkNotDestroyed()
        return this.conversationModule.leaveConversation(this.conversationId)
    }

    /**
     * Closes this conversation locally.
     *
     * When called, the connection to this conversation is closed and the overview is displayed.
     *
     * **Note that:**
     * - Closing does NOT end the conversation.
     * - The person does NOT leave the conversation.
     * - All Conversation api instances for this conversation will be destroyed.
     *
     * The conversation can be joined again either via the UI or using {@link UnbluApi.openConversation}.
     * @see {@link CLOSE} fired after this call.
     * @see {@link endConversation} for details on ending a conversation.
     * @see {@link leavingConversation} for details on leaving a conversation.
     * @see {@link destroy} for details on destroying a conversation.
     * @return A Promise that resolves to null or is rejected with a {@link UnbluApiError} if the call fails.
     */
    public async closeConversation(): Promise<void> {
        this.checkNotDestroyed()
        return this.conversationModule.closeConversation(this.conversationId)
    }

    private checkNotDestroyed(): void {
        if (this.destroyed) throw new UnbluApiError(UnbluErrorType.ILLEGAL_STATE, 'Error: trying to execute method on destroyed conversation object.')
    }

    /**
     * Returns weather this conversation is destroyed or not.
     *
     * Conversations are destroyed if {@link destroy} is called or the conversation is closed.
     * This usually happens when the user navigates back to an overview or into an other conversation.
     * @see {@link destroy}
     * @see {@link CLOSE}
     * @return Weather this conversation is destroyed or not.
     */
    public isDestroyed(): boolean {
        return this.destroyed
    }

    /**
     * Destroys this conversation API instance.
     *
     * Calling destroy will unregister all event listeners and prohibit any further calls to this object.
     * Once the conversation is destroyed, any subsequent calls will reject the returned promise with {@link UnbluErrorType.ILLEGAL_STATE} as the reason.
     *
     * **Note that:**
     * - Destroying does NOT close the conversation .
     * - Destroying does NOT end the conversation.
     * - Destroying does NOT leave the conversation.
     * - Other instances of the same Conversation will NOT be destroyed.
     *
     * This call simply destroys this local API instance to the conversation.
     *
     * A destroyed but still open conversation can be accessed again using {@link UnbluApi.getActiveConversation}.
     *
     * @see {@link isDestroyed}
     * @see {@link closeConversation} for details on how to close a conversation
     * @see {@link endConversation} for details on how to end a conversation
     */
    public destroy(): void {
        if (this.destroyed) return
        this.destroyed = true
        this.eventEmitter.reset()
        for (let event in this.internalListeners) {
            this.offInternal(event as ConversationEventType)
        }
    }
}